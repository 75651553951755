import axios from 'axios'
import { Message } from 'element-ui'
import router from '@/router'
import store from '@/store'
import { getToken } from '@/common/token'

axios.defaults.baseURL = window.g.BASE_URL
axios.interceptors.request.use(
  config => {
    const token = getToken()
    if (token) {
      config.headers.common.Authorization = token
    }
    return config
  },
  error => {
    Message.error({ message: error, center: true })
    return Promise.reject(error)
  }
)
axios.interceptors.response.use(
  response => {
    store.commit('isLoading', false)
    if (response.request.responseType === 'blob') {
      return response.data
    }
    if (response.data.code === 401) {
      Message.error({ message: response.data.msg, center: true })
      store.dispatch('init')
      setTimeout(() => { router.replace('/login') }, 500)
      return Promise.reject(response.data.msg)
    } else if (response.data.code === 400) {
      Message.warning({ message: response.data.msg, center: true })
      return Promise.reject(response.data.msg)
    } else if (response.data.code === 500) {
      Message.error({ message: response.data.msg, center: true })
      return Promise.reject(response.data.msg)
    }
    if (response.data.msg) {
      Message.success({ message: response.data.msg, center: true })
    }
    return response.data.data
  },
  error => {
    store.commit('isLoading', false)
    Message.error({ message: error, center: true })
    return Promise.reject(error)
  }
)

export default axios
