<template>
  <div id="maskFinished">
    <el-dialog
      :title="maskFinishedFormTitle"
      width="900px"
      :visible.sync="maskFinishedDialogVisible"
      :close-on-click-modal="false"
      @close="maskFinishedDialogClose"
    >
      <el-form
        ref="maskFinishedFormRef"
        :model="maskFinishedForm"
        :rules="maskFinishedFormRules"
        label-position="right"
        label-width="120px"
      >
        <el-row>
          <el-col :span="12">
            <el-form-item label="批号" prop="batchNo">
              <el-input v-model="maskFinishedForm.batchNo" placeholder="请输入批号" clearable />
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item label="型号/规格" prop="spec">
              <el-input v-model="maskFinishedForm.spec" placeholder="请输入型号/规格" clearable />
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item label="取样量" prop="samplingQuantity">
              <el-input v-model="maskFinishedForm.samplingQuantity" placeholder="请输入取样量" clearable />
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item label="检验日期" prop="inspectionDate">
              <el-date-picker v-model="maskFinishedForm.inspectionDate" placeholder="请选择检验日期" value-format="yyyy-MM-dd" />
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item label="拉力计设备编号" prop="tensimeterNo">
              <el-input v-model="maskFinishedForm.tensimeterNo" placeholder="请输入拉力计设备编号" clearable />
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item label="直尺编号" prop="rulerNo">
              <el-input v-model="maskFinishedForm.rulerNo" placeholder="请输入直尺编号" clearable />
            </el-form-item>
          </el-col>
          <el-col :span="24">
            <el-form-item label="外观：随机抽取3个样品进行试验，目视检查" label-width="360px" />
            <el-form-item label="质量标准：口罩外观应整洁、形状完好，表面不得有破损、污渍" label-width="480px" />
          </el-col>
          <el-col :span="8">
            <el-form-item label="样品1检测" prop="appearanceResult1">
              <el-radio-group v-model="maskFinishedForm.appearanceResult1">
                <el-radio :label="1">
                  符合
                </el-radio>
                <el-radio :label="0">
                  不符合
                </el-radio>
              </el-radio-group>
            </el-form-item>
          </el-col>
          <el-col :span="8">
            <el-form-item label="样品2检测" prop="appearanceResult2">
              <el-radio-group v-model="maskFinishedForm.appearanceResult2">
                <el-radio :label="1">
                  符合
                </el-radio>
                <el-radio :label="0">
                  不符合
                </el-radio>
              </el-radio-group>
            </el-form-item>
          </el-col>
          <el-col :span="8">
            <el-form-item label="样品3检测" prop="appearanceResult3">
              <el-radio-group v-model="maskFinishedForm.appearanceResult3">
                <el-radio :label="1">
                  符合
                </el-radio>
                <el-radio :label="0">
                  不符合
                </el-radio>
              </el-radio-group>
            </el-form-item>
          </el-col>
          <el-col :span="24">
            <el-form-item label="结构与尺寸：随机抽取3个样品进行试验，实际佩戴，并以通用或专用量具测量" label-width="570px" />
            <el-form-item label="质量标准：口罩佩戴好后，应能罩住佩戴者的口、鼻至下颌。应符合型号规格的规定，最大偏差应不超过±5%" label-width="760px" />
          </el-col>
          <el-col :span="8">
            <el-form-item label="样品1" prop="size1">
              <el-input v-model="maskFinishedForm.size1" placeholder="请输入样品1" clearable />
            </el-form-item>
          </el-col>
          <el-col :span="8">
            <el-form-item label="样品2" prop="size2">
              <el-input v-model="maskFinishedForm.size2" placeholder="请输入样品2" clearable />
            </el-form-item>
          </el-col>
          <el-col :span="8">
            <el-form-item label="样品3" prop="size3">
              <el-input v-model="maskFinishedForm.size3" placeholder="请输入样品3" clearable />
            </el-form-item>
          </el-col>
          <el-col :span="8">
            <el-form-item label="样品1检测" prop="sizeResult1">
              <el-radio-group v-model="maskFinishedForm.sizeResult1">
                <el-radio :label="1">
                  符合
                </el-radio>
                <el-radio :label="0">
                  不符合
                </el-radio>
              </el-radio-group>
            </el-form-item>
          </el-col>
          <el-col :span="8">
            <el-form-item label="样品2检测" prop="sizeResult2">
              <el-radio-group v-model="maskFinishedForm.sizeResult2">
                <el-radio :label="1">
                  符合
                </el-radio>
                <el-radio :label="0">
                  不符合
                </el-radio>
              </el-radio-group>
            </el-form-item>
          </el-col>
          <el-col :span="8">
            <el-form-item label="样品3检测" prop="sizeResult3">
              <el-radio-group v-model="maskFinishedForm.sizeResult3">
                <el-radio :label="1">
                  符合
                </el-radio>
                <el-radio :label="0">
                  不符合
                </el-radio>
              </el-radio-group>
            </el-form-item>
          </el-col>
          <el-col :span="24">
            <el-form-item label="鼻夹：a）随机抽取3个样品进行试验，检查鼻夹材质并手试弯折" label-width="480px" />
            <el-form-item label="质量标准：a）口罩上应配有鼻夹，鼻夹由可塑性材料制成" label-width="440px" />
          </el-col>
          <el-col :span="8">
            <el-form-item label="样品1检测" prop="noseclipResult1">
              <el-radio-group v-model="maskFinishedForm.noseclipResult1">
                <el-radio :label="1">
                  符合
                </el-radio>
                <el-radio :label="0">
                  不符合
                </el-radio>
              </el-radio-group>
            </el-form-item>
          </el-col>
          <el-col :span="8">
            <el-form-item label="样品2检测" prop="noseclipResult2">
              <el-radio-group v-model="maskFinishedForm.noseclipResult2">
                <el-radio :label="1">
                  符合
                </el-radio>
                <el-radio :label="0">
                  不符合
                </el-radio>
              </el-radio-group>
            </el-form-item>
          </el-col>
          <el-col :span="8">
            <el-form-item label="样品3检测" prop="noseclipResult3">
              <el-radio-group v-model="maskFinishedForm.noseclipResult3">
                <el-radio :label="1">
                  符合
                </el-radio>
                <el-radio :label="0">
                  不符合
                </el-radio>
              </el-radio-group>
            </el-form-item>
          </el-col>
          <el-col :span="24">
            <el-form-item label="鼻夹：b）随机抽取3个样品进行试验，取出鼻夹，以通用或专用量具测量" label-width="530px" />
            <el-form-item label="质量标准：b）鼻夹长度应不小于8.0 cm" label-width="330px" />
          </el-col>
          <el-col :span="8">
            <el-form-item label="样品4" prop="noseclip4">
              <el-input v-model="maskFinishedForm.noseclip4" placeholder="请输入样品4" clearable />
            </el-form-item>
          </el-col>
          <el-col :span="8">
            <el-form-item label="样品5" prop="noseclip5">
              <el-input v-model="maskFinishedForm.noseclip5" placeholder="请输入样品5" clearable />
            </el-form-item>
          </el-col>
          <el-col :span="8">
            <el-form-item label="样品6" prop="noseclip6">
              <el-input v-model="maskFinishedForm.noseclip6" placeholder="请输入样品6" clearable />
            </el-form-item>
          </el-col>
          <el-col :span="8">
            <el-form-item label="样品4检测" prop="noseclipResult4">
              <el-radio-group v-model="maskFinishedForm.noseclipResult4">
                <el-radio :label="1">
                  符合
                </el-radio>
                <el-radio :label="0">
                  不符合
                </el-radio>
              </el-radio-group>
            </el-form-item>
          </el-col>
          <el-col :span="8">
            <el-form-item label="样品5检测" prop="noseclipResult5">
              <el-radio-group v-model="maskFinishedForm.noseclipResult5">
                <el-radio :label="1">
                  符合
                </el-radio>
                <el-radio :label="0">
                  不符合
                </el-radio>
              </el-radio-group>
            </el-form-item>
          </el-col>
          <el-col :span="8">
            <el-form-item label="样品6检测" prop="noseclipResult6">
              <el-radio-group v-model="maskFinishedForm.noseclipResult6">
                <el-radio :label="1">
                  符合
                </el-radio>
                <el-radio :label="0">
                  不符合
                </el-radio>
              </el-radio-group>
            </el-form-item>
          </el-col>
          <el-col :span="24">
            <el-form-item label="口罩带：a）随机抽取3个样品进行试验,通过佩戴检查其调节情况" label-width="480px" />
            <el-form-item label="质量标准：a）口罩带应戴取方便" label-width="285px" />
          </el-col>
          <el-col :span="8">
            <el-form-item label="样品1检测" prop="maskBeltResult1">
              <el-radio-group v-model="maskFinishedForm.maskBeltResult1">
                <el-radio :label="1">
                  符合
                </el-radio>
                <el-radio :label="0">
                  不符合
                </el-radio>
              </el-radio-group>
            </el-form-item>
          </el-col>
          <el-col :span="8">
            <el-form-item label="样品2检测" prop="maskBeltResult2">
              <el-radio-group v-model="maskFinishedForm.maskBeltResult2">
                <el-radio :label="1">
                  符合
                </el-radio>
                <el-radio :label="0">
                  不符合
                </el-radio>
              </el-radio-group>
            </el-form-item>
          </el-col>
          <el-col :span="8">
            <el-form-item label="样品3检测" prop="maskBeltResult3">
              <el-radio-group v-model="maskFinishedForm.maskBeltResult3">
                <el-radio :label="1">
                  符合
                </el-radio>
                <el-radio :label="0">
                  不符合
                </el-radio>
              </el-radio-group>
            </el-form-item>
          </el-col>
          <el-col :span="24">
            <el-form-item label="口罩带：b）随机抽取3个样品进行试验,用拉力机进行测量，持续5s" label-width="500px" />
            <el-form-item label="质量标准：b）每根口罩带与口罩体连接点处的断裂强力应不小于10 N" label-width="515px" />
          </el-col>
          <el-col :span="8">
            <el-form-item label="样品4" prop="maskBelt4">
              <el-input v-model="maskFinishedForm.maskBelt4" placeholder="请输入样品4" clearable />
            </el-form-item>
          </el-col>
          <el-col :span="8">
            <el-form-item label="样品5" prop="maskBelt5">
              <el-input v-model="maskFinishedForm.maskBelt5" placeholder="请输入样品5" clearable />
            </el-form-item>
          </el-col>
          <el-col :span="8">
            <el-form-item label="样品6" prop="maskBelt6">
              <el-input v-model="maskFinishedForm.maskBelt6" placeholder="请输入样品6" clearable />
            </el-form-item>
          </el-col>
          <el-col :span="8">
            <el-form-item label="样品4检测" prop="maskBeltResult4">
              <el-radio-group v-model="maskFinishedForm.maskBeltResult4">
                <el-radio :label="1">
                  符合
                </el-radio>
                <el-radio :label="0">
                  不符合
                </el-radio>
              </el-radio-group>
            </el-form-item>
          </el-col>
          <el-col :span="8">
            <el-form-item label="样品5检测" prop="maskBeltResult5">
              <el-radio-group v-model="maskFinishedForm.maskBeltResult5">
                <el-radio :label="1">
                  符合
                </el-radio>
                <el-radio :label="0">
                  不符合
                </el-radio>
              </el-radio-group>
            </el-form-item>
          </el-col>
          <el-col :span="8">
            <el-form-item label="样品6检测" prop="maskBeltResult6">
              <el-radio-group v-model="maskFinishedForm.maskBeltResult6">
                <el-radio :label="1">
                  符合
                </el-radio>
                <el-radio :label="0">
                  不符合
                </el-radio>
              </el-radio-group>
            </el-form-item>
          </el-col>
        </el-row>
      </el-form>
      <div slot="footer">
        <el-button @click="maskFinishedDialogVisible = false">
          取 消
        </el-button>
        <el-button type="primary" @click="maskFinishedFormSubmit">
          确 定
        </el-button>
      </div>
    </el-dialog>
    <el-form inline size="small">
      <el-form-item label="批号">
        <el-input v-model="searchForm.batchNo" placeholder="请输入批号" clearable />
      </el-form-item>
      <el-form-item>
        <el-button type="primary" icon="el-icon-search" @click="handleSearch">
          搜索
        </el-button>
        <el-button type="primary" icon="el-icon-plus" @click="handleAdd">
          新增
        </el-button>
      </el-form-item>
    </el-form>
    <el-table
      :data="maskFinishedPage.list"
      row-key="id"
      :cell-style="{padding: '6px'}"
      :header-cell-style="{background: '#f8f8f9'}"
      :height="getTableHeight()"
    >
      <el-table-column prop="batchNo" label="批号" />
      <el-table-column prop="spec" label="型号/规格" />
      <el-table-column prop="samplingQuantity" label="取样量" />
      <el-table-column label="检验日期">
        <template slot-scope="scope">
          <span v-if="scope.row.inspectionDate">{{ scope.row.inspectionDate.substring(0, 10) }}</span>
        </template>
      </el-table-column>
      <el-table-column prop="tensimeterNo" label="拉力计设备编号" />
      <el-table-column prop="rulerNo" label="直尺编号" />
      <el-table-column label="外观样品1检测">
        <template slot-scope="scope">
          <span v-if="scope.row.appearanceResult1 === 0">不符合</span>
          <span v-if="scope.row.appearanceResult1 === 1">符合</span>
        </template>
      </el-table-column>
      <el-table-column label="外观样品2检测">
        <template slot-scope="scope">
          <span v-if="scope.row.appearanceResult2 === 0">不符合</span>
          <span v-if="scope.row.appearanceResult2 === 1">符合</span>
        </template>
      </el-table-column>
      <el-table-column label="外观样品3检测">
        <template slot-scope="scope">
          <span v-if="scope.row.appearanceResult3 === 0">不符合</span>
          <span v-if="scope.row.appearanceResult3 === 1">符合</span>
        </template>
      </el-table-column>
      <el-table-column prop="size1" label="结构与尺寸样品1" />
      <el-table-column label="结构与尺寸样品1检测">
        <template slot-scope="scope">
          <span v-if="scope.row.sizeResult1 === 0">不符合</span>
          <span v-if="scope.row.sizeResult1 === 1">符合</span>
        </template>
      </el-table-column>
      <el-table-column prop="size2" label="结构与尺寸样品2" />
      <el-table-column label="结构与尺寸样品2检测">
        <template slot-scope="scope">
          <span v-if="scope.row.sizeResult2 === 0">不符合</span>
          <span v-if="scope.row.sizeResult2 === 1">符合</span>
        </template>
      </el-table-column>
      <el-table-column prop="size3" label="结构与尺寸样品3" />
      <el-table-column label="结构与尺寸样品3检测">
        <template slot-scope="scope">
          <span v-if="scope.row.sizeResult3 === 0">不符合</span>
          <span v-if="scope.row.sizeResult3 === 1">符合</span>
        </template>
      </el-table-column>
      <el-table-column label="鼻夹样品1检测">
        <template slot-scope="scope">
          <span v-if="scope.row.noseclipResult1 === 0">不符合</span>
          <span v-if="scope.row.noseclipResult1 === 1">符合</span>
        </template>
      </el-table-column>
      <el-table-column label="鼻夹样品2检测">
        <template slot-scope="scope">
          <span v-if="scope.row.noseclipResult2 === 0">不符合</span>
          <span v-if="scope.row.noseclipResult2 === 1">符合</span>
        </template>
      </el-table-column>
      <el-table-column label="鼻夹样品3检测">
        <template slot-scope="scope">
          <span v-if="scope.row.noseclipResult3 === 0">不符合</span>
          <span v-if="scope.row.noseclipResult3 === 1">符合</span>
        </template>
      </el-table-column>
      <el-table-column prop="noseclip4" label="鼻夹样品4" />
      <el-table-column label="鼻夹样品4检测">
        <template slot-scope="scope">
          <span v-if="scope.row.noseclipResult4 === 0">不符合</span>
          <span v-if="scope.row.noseclipResult4 === 1">符合</span>
        </template>
      </el-table-column>
      <el-table-column prop="noseclip5" label="鼻夹样品5" />
      <el-table-column label="鼻夹样品5检测">
        <template slot-scope="scope">
          <span v-if="scope.row.noseclipResult5 === 0">不符合</span>
          <span v-if="scope.row.noseclipResult5 === 1">符合</span>
        </template>
      </el-table-column>
      <el-table-column prop="noseclip6" label="鼻夹样品6" />
      <el-table-column label="鼻夹样品6检测">
        <template slot-scope="scope">
          <span v-if="scope.row.noseclipResult6 === 0">不符合</span>
          <span v-if="scope.row.noseclipResult6 === 1">符合</span>
        </template>
      </el-table-column>
      <el-table-column label="口罩带样品1检测">
        <template slot-scope="scope">
          <span v-if="scope.row.maskBeltResult1 === 0">不符合</span>
          <span v-if="scope.row.maskBeltResult1 === 1">符合</span>
        </template>
      </el-table-column>
      <el-table-column label="口罩带样品2检测">
        <template slot-scope="scope">
          <span v-if="scope.row.maskBeltResult2 === 0">不符合</span>
          <span v-if="scope.row.maskBeltResult2 === 1">符合</span>
        </template>
      </el-table-column>
      <el-table-column label="口罩带样品3检测">
        <template slot-scope="scope">
          <span v-if="scope.row.maskBeltResult3 === 0">不符合</span>
          <span v-if="scope.row.maskBeltResult3 === 1">符合</span>
        </template>
      </el-table-column>
      <el-table-column prop="maskBelt4" label="口罩带样品4" />
      <el-table-column label="口罩带样品4检测">
        <template slot-scope="scope">
          <span v-if="scope.row.maskBeltResult4 === 0">不符合</span>
          <span v-if="scope.row.maskBeltResult4 === 1">符合</span>
        </template>
      </el-table-column>
      <el-table-column prop="maskBelt5" label="口罩带样品5" />
      <el-table-column label="口罩带样品5检测">
        <template slot-scope="scope">
          <span v-if="scope.row.maskBeltResult5 === 0">不符合</span>
          <span v-if="scope.row.maskBeltResult5 === 1">符合</span>
        </template>
      </el-table-column>
      <el-table-column prop="maskBelt6" label="口罩带样品6" />
      <el-table-column label="口罩带样品6检测">
        <template slot-scope="scope">
          <span v-if="scope.row.maskBeltResult6 === 0">不符合</span>
          <span v-if="scope.row.maskBeltResult6 === 1">符合</span>
        </template>
      </el-table-column>
      <el-table-column
        label="操作"
        align="center"
        width="200"
        fixed="right"
      >
        <template slot-scope="scope">
          <el-button
            type="text"
            icon="el-icon-delete"
            size="small"
            @click.stop="handleDelete(scope.$index, scope.row)"
          >
            删除
          </el-button>
          <el-button
            type="text"
            icon="el-icon-edit"
            size="small"
            @click.stop="handleUpdate(scope.$index, scope.row)"
          >
            修改
          </el-button>
          <el-button
            type="text"
            icon="el-icon-info"
            size="small"
            @click.stop="handleInfo(scope.$index, scope.row)"
          >
            详情
          </el-button>
        </template>
      </el-table-column>
    </el-table>
    <el-pagination
      :total="maskFinishedPage.total"
      :current-page="searchForm.pageNum"
      :page-size="searchForm.pageSize"
      :page-sizes="[10, 15, 20]"
      layout="total, sizes, prev, pager, next, jumper"
      background
      @current-change="pageNumChange"
      @size-change="pageSizeChange"
    />
  </div>
</template>

<script>
import { addMaskFinished, deleteMaskFinished, updateMaskFinished, selectMaskFinishedInfo, selectMaskFinishedList } from '@/api/quality/maskFinished'

export default {
  data () {
    return {
      maskFinishedDialogVisible: false,
      maskFinishedFormTitle: '',
      maskFinishedForm: {
        id: '',
        batchNo: '',
        spec: '',
        samplingQuantity: '',
        inspectionDate: '',
        tensimeterNo: '',
        rulerNo: '',
        appearanceResult1: '',
        appearanceResult2: '',
        appearanceResult3: '',
        size1: '',
        size2: '',
        size3: '',
        sizeResult1: '',
        sizeResult2: '',
        sizeResult3: '',
        noseclipResult1: '',
        noseclipResult2: '',
        noseclipResult3: '',
        noseclipResult4: '',
        noseclipResult5: '',
        noseclipResult6: '',
        noseclip4: '',
        noseclip5: '',
        noseclip6: '',
        maskBeltResult1: '',
        maskBeltResult2: '',
        maskBeltResult3: '',
        maskBeltResult4: '',
        maskBeltResult5: '',
        maskBeltResult6: '',
        maskBelt4: '',
        maskBelt5: '',
        maskBelt6: ''
      },
      maskFinishedFormRules: {
        batchNo: [{ required: true, message: '批号不能为空', trigger: ['blur', 'change']}]
      },
      maskFinishedPage: {
        list: [],
        total: 0
      },
      searchForm: {
        pageNum: 1,
        pageSize: 10,
        batchNo: ''
      }
    }
  },
  created () {
    selectMaskFinishedList(this.searchForm).then(res => {
      this.maskFinishedPage = res
    })
  },
  methods: {
    maskFinishedDialogClose () {
      this.$refs.maskFinishedFormRef.resetFields()
    },
    maskFinishedFormSubmit () {
      if (this.maskFinishedFormTitle === '一次性使用医用口罩成品检验记录详情') {
        this.maskFinishedDialogVisible = false
        return
      }
      this.$refs.maskFinishedFormRef.validate(async valid => {
        if (valid) {
          if (this.maskFinishedFormTitle === '新增一次性使用医用口罩成品检验记录') {
            await addMaskFinished(this.maskFinishedForm)
          } else if (this.maskFinishedFormTitle === '修改一次性使用医用口罩成品检验记录') {
            await updateMaskFinished(this.maskFinishedForm)
          }
          this.maskFinishedPage = await selectMaskFinishedList(this.searchForm)
          this.maskFinishedDialogVisible = false
        }
      })
    },
    handleAdd () {
      this.maskFinishedFormTitle = '新增一次性使用医用口罩成品检验记录'
      this.maskFinishedDialogVisible = true
    },
    handleDelete (index, row) {
      this.$confirm('确认删除？', '提示', {
        type: 'warning'
      }).then(async () => {
        await deleteMaskFinished(row.id)
        if (this.maskFinishedPage.list.length === 1 && this.searchForm.pageNum > 1) {
          this.searchForm.pageNum--
        }
        this.maskFinishedPage = await selectMaskFinishedList(this.searchForm)
      })
    },
    handleUpdate (index, row) {
      this.maskFinishedFormTitle = '修改一次性使用医用口罩成品检验记录'
      this.maskFinishedDialogVisible = true
      this.selectMaskFinishedInfoById(row.id)
    },
    handleInfo (index, row) {
      this.maskFinishedFormTitle = '一次性使用医用口罩成品检验记录详情'
      this.maskFinishedDialogVisible = true
      this.selectMaskFinishedInfoById(row.id)
    },
    selectMaskFinishedInfoById (id) {
      selectMaskFinishedInfo(id).then(res => {
        this.maskFinishedForm.id = res.id
        this.maskFinishedForm.batchNo = res.batchNo
        this.maskFinishedForm.spec = res.spec
        this.maskFinishedForm.samplingQuantity = res.samplingQuantity
        this.maskFinishedForm.inspectionDate = res.inspectionDate
        this.maskFinishedForm.tensimeterNo = res.tensimeterNo
        this.maskFinishedForm.rulerNo = res.rulerNo
        this.maskFinishedForm.appearanceResult1 = res.appearanceResult1
        this.maskFinishedForm.appearanceResult2 = res.appearanceResult2
        this.maskFinishedForm.appearanceResult3 = res.appearanceResult3
        this.maskFinishedForm.size1 = res.size1
        this.maskFinishedForm.size2 = res.size2
        this.maskFinishedForm.size3 = res.size3
        this.maskFinishedForm.sizeResult1 = res.sizeResult1
        this.maskFinishedForm.sizeResult2 = res.sizeResult2
        this.maskFinishedForm.sizeResult3 = res.sizeResult3
        this.maskFinishedForm.noseclipResult1 = res.noseclipResult1
        this.maskFinishedForm.noseclipResult2 = res.noseclipResult2
        this.maskFinishedForm.noseclipResult3 = res.noseclipResult3
        this.maskFinishedForm.noseclipResult4 = res.noseclipResult4
        this.maskFinishedForm.noseclipResult5 = res.noseclipResult5
        this.maskFinishedForm.noseclipResult6 = res.noseclipResult6
        this.maskFinishedForm.noseclip4 = res.noseclip4
        this.maskFinishedForm.noseclip5 = res.noseclip5
        this.maskFinishedForm.noseclip6 = res.noseclip6
        this.maskFinishedForm.maskBeltResult1 = res.maskBeltResult1
        this.maskFinishedForm.maskBeltResult2 = res.maskBeltResult2
        this.maskFinishedForm.maskBeltResult3 = res.maskBeltResult3
        this.maskFinishedForm.maskBeltResult4 = res.maskBeltResult4
        this.maskFinishedForm.maskBeltResult5 = res.maskBeltResult5
        this.maskFinishedForm.maskBeltResult6 = res.maskBeltResult6
        this.maskFinishedForm.maskBelt4 = res.maskBelt4
        this.maskFinishedForm.maskBelt5 = res.maskBelt5
        this.maskFinishedForm.maskBelt6 = res.maskBelt6
      })
    },
    handleSearch () {
      this.searchForm.pageNum = 1
      selectMaskFinishedList(this.searchForm).then(res => {
        this.maskFinishedPage = res
      })
    },
    pageNumChange (pageNum) {
      this.searchForm.pageNum = pageNum
      selectMaskFinishedList(this.searchForm).then(res => {
        this.maskFinishedPage = res
      })
    },
    pageSizeChange (pageSize) {
      this.searchForm.pageSize = pageSize
      this.searchForm.pageNum = 1
      selectMaskFinishedList(this.searchForm).then(res => {
        this.maskFinishedPage = res
      })
    }
  }
}
</script>

<style>
</style>
